import React from 'react';
import { useParams } from 'react-router-dom';
import { categories } from '../data/categories';
import { cities } from '../data/cities';
import { usePlaces } from '../hooks/usePlaces';
import MerchantCard from '../components/MerchantCard';
import LoadingSkeleton from '../components/LoadingSkeleton';
import SEOHead from '../components/SEOHead';
import { Star, AlertCircle, ShieldCheck, Clock } from 'lucide-react';

const getDescriptionText = (categoryTitle: string): string => {
  // Special cases that need custom handling
  const specialCases: Record<string, string> = {
    'SPA Centri': 'SPA centru',
    'Tulkošanas Biroji': 'tulkošanas biroju',
    'Kravu Pārvadājumi': 'kravu pārvadājumu',
    'Pārvākšanās Serviss': 'pārvākšanās servisa',
    'Kurjeru Pakalpojumi': 'kurjeru',
    'Auto Noma': 'auto nomas',
    'Signalizācijas Sistēmas': 'signalizācijas sistēmu',
    'Datoru Serviss': 'datoru servisa',
    'Interneta Pakalpojumi': 'interneta',
    'Mājaslapu Izstrāde': 'mājaslapu izstrādes',
    'Mēbeļu Remonts': 'mēbeļu remonta',
    'Sadzīves Tehnika': 'sadzīves tehnikas',
    'Ķīmiskā Tīrītava': 'ķīmiskās tīrīšanas',
    'Teritorijas Uzkopšana': 'teritorijas uzkopšanas',
    'Zemes Darbi': 'zemes darbu',
    'Teritorijas Labiekārtošana': 'teritorijas labiekārtošanas',
    'Valodu Kursi': 'valodu kursu',
    'Sporta Nodarbības': 'sporta nodarbību',
    'Svētku Aģentūras': 'svētku aģentūru',
    'Muzikālie Pakalpojumi': 'muzikālo'
  };

  // Check for special cases first
  if (specialCases[categoryTitle]) {
    return specialCases[categoryTitle];
  }

  // Handle professions ending with -nieks/-nieki
  if (categoryTitle.endsWith('nieki')) {
    return categoryTitle.slice(0, -1) + 'u'; // elektriķi -> elektriķu
  }
  if (categoryTitle.endsWith('nieks')) {
    return categoryTitle.slice(0, -5) + 'nieku'; // galdnieks -> galdnieku
  }
  
  // Handle profession endings
  if (categoryTitle.endsWith('tājs')) {
    return categoryTitle.slice(0, -3) + 'tāju'; // pārvadātājs -> pārvadātāju
  }
  if (categoryTitle.endsWith('tāji')) {
    return categoryTitle.slice(0, -4) + 'tāju'; // pārvadātāji -> pārvadātāju
  }
  if (categoryTitle.endsWith('ējs')) {
    return categoryTitle.slice(0, -3) + 'ēju'; // pārdevējs -> pārdevēju
  }
  if (categoryTitle.endsWith('ēji')) {
    return categoryTitle.slice(0, -3) + 'ēju'; // pārdevēji -> pārdevēju
  }

  // Handle service types
  const serviceTypes: Record<string, string> = {
    'serviss': 'servisa',
    'centrs': 'centra',
    'centri': 'centru',
    'darbi': 'darbu',
    'sistēmas': 'sistēmu',
    'pakalpojumi': 'pakalpojumu',
    'remonts': 'remonta',
    'montāža': 'montāžas',
    'tīrīšana': 'tīrīšanas',
    'kopšana': 'kopšanas',
    'labiekārtošana': 'labiekārtošanas',
    'biroji': 'biroju',
    'birojs': 'biroja',
    'niecība': 'niecības',
    'ība': 'ības',
    'ārstniecība': 'ārstniecības',
    'ārsts': 'ārsta',
    'ārsti': 'ārstu',
    'skola': 'skolas',
    'skolas': 'skolu',
    'aģentūra': 'aģentūras',
    'aģentūras': 'aģentūru',
    'firma': 'firmas',
    'firmas': 'firmu',
    'studija': 'studijas',
    'studijas': 'studiju',
    'apdrošināšana': 'apdrošināšanas',
    'zobārstniecība': 'zobārstniecības',
    'veterinārija': 'veterinārijas',
    'apsardze': 'apsardzes',
    'dezinfekcija': 'dezinfekcijas'
  };

  // Find matching ending and replace it
  for (const [ending, genitive] of Object.entries(serviceTypes)) {
    if (categoryTitle.toLowerCase().endsWith(ending.toLowerCase())) {
      return categoryTitle.slice(0, -ending.length) + genitive;
    }
  }

  // If no specific rule matches, return the original form
  return categoryTitle;
};

export default function CityPage() {
  const { categoryId, cityId } = useParams<{ categoryId: string; cityId: string }>();
  const category = categories.find((c) => c.id === categoryId);
  const city = cities.find((c) => c.id === cityId);
  
  const { merchants, loading, error } = usePlaces(categoryId || '', city?.name || '');

  if (!category || !city) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900">Nav atrasts</h2>
          <p className="mt-2 text-gray-600">Izvēlētā kategorija vai pilsēta nav atrasta.</p>
        </div>
      </div>
    );
  }

  const descriptionText = getDescriptionText(category.title);
  const pageTitle = `${category.title} ${city.locative}`;
  const pageDescription = `Meklējat uzticamus ${descriptionText} pakalpojumus ${city.locative}? Šeit atradīsiet pārbaudītus speciālistus ar reālām atsauksmēm un aktuālu kontaktinformāciju.`;
  const canonicalUrl = `https://visipakalpojumi.lv/pakalpojums/${categoryId}/${cityId}`;

  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": pageTitle,
    "description": pageDescription,
    "address": {
      "@type": "PostalAddress",
      "addressLocality": city.name,
      "addressCountry": "LV"
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <SEOHead
        title={pageTitle}
        description={pageDescription}
        canonicalUrl={canonicalUrl}
        schema={schema}
      />

      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Par {descriptionText} pakalpojumiem {city.locative}
          </h1>
          <p className="text-lg text-gray-600">
            {city.name} piedāvā plašu {descriptionText} pakalpojumu klāstu. Mūsu platformā jūs varat atrast uzticamus 
            speciālistus, kas nodrošina kvalitatīvus pakalpojumus par saprātīgām cenām.
          </p>

          <h3 className="text-xl font-semibold mt-6 mb-4">Kā Izvēlēties Labāko Speciālistu?</h3>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>Pārbaudiet uzņēmuma vērtējumus un atsauksmes</li>
            <li>Salīdziniet piedāvātos pakalpojumus un cenas</li>
            <li>Pārliecinieties par speciālista pieejamību</li>
            <li>Jautājiet par garantiju un kvalitātes apliecinājumiem</li>
          </ul>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            {loading ? (
              <LoadingSkeleton />
            ) : error ? (
              <div className="bg-red-50 p-4 rounded-lg">
                <div className="flex items-center">
                  <AlertCircle className="w-5 h-5 text-red-400 mr-2" />
                  <p className="text-red-700">{error}</p>
                </div>
              </div>
            ) : merchants.length > 0 ? (
              <div className="space-y-6">
                {merchants.map((merchant) => (
                  <MerchantCard key={merchant.id} merchant={merchant} />
                ))}
              </div>
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-600">
                  Diemžēl šobrīd nav atrasti pakalpojumu sniedzēji šajā kategorijā.
                </p>
              </div>
            )}
          </div>

          <div className="lg:col-span-1">
            <div className="bg-white rounded-xl shadow-md p-6 sticky top-4">
              <h2 className="text-xl font-semibold mb-6">Par pakalpojumu sniedzējiem {city.locative}</h2>
              
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="bg-indigo-50 p-2 rounded-lg">
                    <Star className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Reitings un atsauksmes</h3>
                    <p className="text-sm text-gray-600">Pārbaudītas klientu atsauksmes un vērtējumi</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-indigo-50 p-2 rounded-lg">
                    <ShieldCheck className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Pārbaudīti speciālisti</h3>
                    <p className="text-sm text-gray-600">Uzticami un profesionāli pakalpojumu sniedzēji</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="bg-indigo-50 p-2 rounded-lg">
                    <Clock className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Ātra atsaucība</h3>
                    <p className="text-sm text-gray-600">Operatīva pakalpojumu sniegšana</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}