import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Mail, MapPin, Phone } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* About Section */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Par Mums</h3>
            <p className="text-sm">
              visiPakalpojumi.lv ir vadošā platforma Latvijā, kas apvieno uzticamus 
              profesionāļus ar klientiem. Mēs rūpējamies par jūsu laiku un ērtībām, 
              nodrošinot vienkāršu piekļuvi kvalitatīviem pakalpojumiem.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Noderīgas Saites</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/par-mums" className="text-sm hover:text-white transition-colors">
                  Uzzināt Vairāk Par Mums
                </Link>
              </li>
              <li>
                <Link to="/kontakti" className="text-sm hover:text-white transition-colors">
                  Sazināties ar Mums
                </Link>
              </li>
              <li>
                <Link to="/privatuma-politika" className="text-sm hover:text-white transition-colors">
                  Privātuma Aizsardzība
                </Link>
              </li>
              <li>
                <Link to="/lietosanas-noteikumi" className="text-sm hover:text-white transition-colors">
                  Pakalpojuma Noteikumi
                </Link>
              </li>
            </ul>
          </div>

          {/* Popular Categories */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Populārākie Pakalpojumi</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/pakalpojums/elektriki" className="text-sm hover:text-white transition-colors">
                  Elektriķu Pakalpojumi
                </Link>
              </li>
              <li>
                <Link to="/pakalpojums/santehniki" className="text-sm hover:text-white transition-colors">
                  Santehnikas Darbi
                </Link>
              </li>
              <li>
                <Link to="/pakalpojums/remontdarbi" className="text-sm hover:text-white transition-colors">
                  Dzīvokļu Remonts
                </Link>
              </li>
              <li>
                <Link to="/pakalpojums/autoserviss" className="text-sm hover:text-white transition-colors">
                  Auto Apkope un Remonts
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact & Social */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Kontaktinformācija</h3>
            <div className="space-y-4">
              <p className="text-sm flex items-center">
                <Mail className="inline-block w-4 h-4 mr-2" />
                info@visipakalpojumi.lv
              </p>
              <p className="text-sm flex items-center">
                <Phone className="inline-block w-4 h-4 mr-2" />
                +371 20000000
              </p>
              <p className="text-sm flex items-center">
                <MapPin className="inline-block w-4 h-4 mr-2" />
                Rīga, Latvija
              </p>
              <div className="flex space-x-4 pt-2">
                <a 
                  href="https://facebook.com/visipakalpojumi" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:text-white transition-colors"
                  aria-label="Sekot mums Facebook"
                >
                  <Facebook className="w-6 h-6" />
                </a>
                <a 
                  href="https://instagram.com/visipakalpojumi" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:text-white transition-colors"
                  aria-label="Sekot mums Instagram"
                >
                  <Instagram className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-800">
          <p className="text-center text-sm">
            © {new Date().getFullYear()} visiPakalpojumi.lv | Visas tiesības aizsargātas
          </p>
        </div>
      </div>
    </footer>
  );
}