import React from 'react';
import { Link } from 'react-router-dom';
import * as Icons from 'lucide-react';
import { ServiceCategory } from '../types';

interface CategoryCardProps {
  category: ServiceCategory;
}

export default function CategoryCard({ category }: CategoryCardProps) {
  const IconComponent = (Icons as any)[category.icon];

  return (
    <Link
      to={`/pakalpojums/${category.id}`}
      className="block group transform transition-all duration-300 hover:-translate-y-1"
    >
      <div className="bg-white rounded-xl shadow-md h-[180px] p-4 transition-all duration-300 hover:shadow-xl border border-gray-100 flex flex-col items-center text-center">
        <div className="bg-indigo-50 rounded-lg p-2.5 group-hover:bg-indigo-100 transition-colors duration-300 mb-2">
          {IconComponent && (
            <IconComponent className="w-6 h-6 text-indigo-600 group-hover:scale-110 transition-transform duration-300" />
          )}
        </div>
        <h3 className="text-lg font-semibold text-gray-900 group-hover:text-indigo-600 transition-colors duration-300 mb-1.5 line-clamp-1">
          {category.title}
        </h3>
        <p className="text-sm text-gray-600 group-hover:text-gray-900 transition-colors duration-300 line-clamp-3">
          {category.description}
        </p>
      </div>
    </Link>
  );
}