import { Loader } from '@googlemaps/js-api-loader';

// Use environment variable for API key
const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '';

// Cache for geocoding results to reduce API calls
const geocodeCache: Record<string, google.maps.LatLngLiteral> = {};

// Initialize loader with proper settings and rate limiting
export const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
  language: 'lv',
  region: 'LV',
  retries: 3,
  retry_interval: 1000
});

// Initialize maps with error handling
export const initGoogleMaps = async () => {
  try {
    const google = await loader.load();
    return google;
  } catch (error) {
    console.error('Error initializing Google Maps:', error);
    throw new Error('Failed to initialize Google Maps. Please try again later.');
  }
};

// Get location with caching to reduce API calls
export const getLatLngFromCity = async (city: string): Promise<google.maps.LatLngLiteral> => {
  const cacheKey = `${city.toLowerCase()}_lv`;
  
  if (geocodeCache[cacheKey]) {
    return geocodeCache[cacheKey];
  }

  try {
    const google = await initGoogleMaps();
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode(
        { 
          address: `${city}, Latvia`,
          region: 'lv'
        },
        (results, status) => {
          if (status === 'OK' && results && results[0]?.geometry?.location) {
            const location = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            };
            geocodeCache[cacheKey] = location;
            resolve(location);
          } else if (status === 'OVER_QUERY_LIMIT') {
            reject(new Error('Service temporarily unavailable. Please try again later.'));
          } else {
            reject(new Error(`Location not found for ${city}`));
          }
        }
      );
    });
  } catch (error) {
    console.error('Geocoding error:', error);
    throw error;
  }
};