import React, { useState } from 'react';
import { Mail, MapPin, Phone, Send, AlertCircle, CheckCircle } from 'lucide-react';
import SEOHead from '../components/SEOHead';
import { sendEmail } from '../services/emailService';

export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const success = await sendEmail(formData);
      if (success) {
        setStatus('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }

    setTimeout(() => {
      setStatus('idle');
    }, 5000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Sazinies ar mums | visiPakalpojumi.lv",
    "description": "Sazinieties ar visiPakalpojumi.lv komandu. Mēs esam šeit, lai palīdzētu jums atrast labākos pakalpojumu sniedzējus Latvijā.",
    "url": "https://visipakalpojumi.lv/kontakti",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+371 20000000",
      "contactType": "customer service",
      "email": "info@visipakalpojumi.lv",
      "areaServed": "LV"
    }
  };

  return (
    <>
      <SEOHead
        title="Sazinies ar mums"
        description="Sazinieties ar visiPakalpojumi.lv komandu. Mēs esam šeit, lai palīdzētu jums atrast labākos pakalpojumu sniedzējus Latvijā."
        canonicalUrl="https://visipakalpojumi.lv/kontakti"
        schema={schema}
      />

      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">Sazinies ar mums</h1>
              <p className="mt-3 text-lg text-gray-500">
                Mēs vienmēr esam gatavi palīdzēt un atbildēt uz jūsu jautājumiem. 
                Saņemsiet atbildi 24 stundu laikā.
              </p>

              <div className="mt-9">
                <div className="flex items-center space-x-3 group">
                  <div className="flex-shrink-0 bg-indigo-50 p-3 rounded-lg group-hover:bg-indigo-100 transition-colors duration-200">
                    <Phone className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div className="text-base text-gray-500">
                    <p className="font-medium text-gray-900">+371 20000000</p>
                    <p className="mt-1">Darba dienās 9:00 - 18:00</p>
                  </div>
                </div>

                <div className="mt-6 flex items-center space-x-3 group">
                  <div className="flex-shrink-0 bg-indigo-50 p-3 rounded-lg group-hover:bg-indigo-100 transition-colors duration-200">
                    <Mail className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div className="text-base text-gray-500">
                    <p className="font-medium text-gray-900">info@visipakalpojumi.lv</p>
                    <p className="mt-1">Atbildam 24h laikā</p>
                  </div>
                </div>

                <div className="mt-6 flex items-center space-x-3 group">
                  <div className="flex-shrink-0 bg-indigo-50 p-3 rounded-lg group-hover:bg-indigo-100 transition-colors duration-200">
                    <MapPin className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div className="text-base text-gray-500">
                    <p className="font-medium text-gray-900">Rīga, Latvija</p>
                    <p className="mt-1">LV-1050</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-12 sm:mt-16 md:mt-0 bg-gray-50 rounded-2xl p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Nosūtīt ziņojumu</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Vārds, uzvārds
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    E-pasta adrese
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Ziņojums
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      required
                      value={formData.message}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={status === 'sending'}
                    className={`w-full inline-flex justify-center items-center rounded-lg border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm ${
                      status === 'sending'
                        ? 'bg-indigo-400 cursor-not-allowed'
                        : 'bg-indigo-600 hover:bg-indigo-700'
                    } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors duration-200`}
                  >
                    {status === 'sending' ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Sūta...
                      </>
                    ) : (
                      <>
                        <Send className="w-5 h-5 mr-2" />
                        Nosūtīt ziņojumu
                      </>
                    )}
                  </button>
                </div>

                {status === 'success' && (
                  <div className="rounded-lg bg-green-50 p-4 flex items-center">
                    <CheckCircle className="w-5 h-5 text-green-400 mr-2" />
                    <p className="text-green-800">
                      Paldies! Jūsu ziņojums ir nosūtīts. Mēs ar jums sazināsimies tuvākajā laikā.
                    </p>
                  </div>
                )}

                {status === 'error' && (
                  <div className="rounded-lg bg-red-50 p-4 flex items-center">
                    <AlertCircle className="w-5 h-5 text-red-400 mr-2" />
                    <p className="text-red-800">
                      Diemžēl radās kļūda. Lūdzu, mēģiniet vēlreiz vai sazinieties ar mums pa e-pastu.
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}