import { CategoryConfig } from '../types/search';

export function validateResult(
  place: google.maps.places.PlaceResult,
  config: CategoryConfig
): boolean {
  if (!place.name) return false;

  // Only exclude permanently closed places
  if (place.business_status === 'PERMANENTLY_CLOSED') return false;

  // Check if place matches any of the business types
  const hasValidType = place.types?.some(type => 
    config.businessTypes.includes(type)
  );

  // Check if name matches any of the search terms or patterns
  const nameMatches = config.searchTerms.some(term => 
    place.name?.toLowerCase().includes(term.toLowerCase())
  ) || config.namePatterns.some(pattern => 
    pattern.test(place.name || '')
  );

  // Check for excluded keywords
  const hasExcludedKeyword = config.excludeKeywords.some(keyword =>
    place.name?.toLowerCase().includes(keyword.toLowerCase())
  );
  if (hasExcludedKeyword) return false;

  // Accept if either type matches or name matches
  return hasValidType || nameMatches;
}