import React from 'react';
import { Shield, Lock, Eye, FileText, UserCheck, Bell } from 'lucide-react';
import SEOHead from '../components/SEOHead';

export default function PrivacyPage() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Privātuma Aizsardzība | visiPakalpojumi.lv",
    "description": "visiPakalpojumi.lv privātuma politika. Uzziniet, kā mēs aizsargājam jūsu personas datus un nodrošinām to drošību.",
    "url": "https://visipakalpojumi.lv/privatuma-politika"
  };

  return (
    <>
      <SEOHead
        title="Privātuma Aizsardzība"
        description="visiPakalpojumi.lv privātuma politika. Uzziniet, kā mēs aizsargājam jūsu personas datus un nodrošinām to drošību."
        canonicalUrl="https://visipakalpojumi.lv/privatuma-politika"
        schema={schema}
      />

      <div className="bg-white">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80"
              alt="Privātuma politika"
              className="h-full w-full object-cover opacity-10"
            />
          </div>

          <div className="relative max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
                Privātuma Aizsardzība
              </h1>
              <p className="mt-4 text-xl text-gray-600">
                Jūsu privātums ir mūsu prioritāte. Uzziniet, kā mēs aizsargājam jūsu datus.
              </p>
            </div>

            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
              <div className="grid gap-8 md:grid-cols-2 mb-8">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 bg-indigo-50 rounded-lg p-3">
                    <Shield className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Datu Drošība</h3>
                    <p className="mt-1 text-gray-600">
                      Mēs izmantojam modernās drošības tehnoloģijas, lai aizsargātu jūsu datus.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 bg-indigo-50 rounded-lg p-3">
                    <Lock className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Datu Šifrēšana</h3>
                    <p className="mt-1 text-gray-600">
                      Visa sensitīvā informācija tiek šifrēta, izmantojot SSL protokolu.
                    </p>
                  </div>
                </div>
              </div>

              <div className="prose prose-lg max-w-none">
                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4">
                  <Eye className="w-6 h-6 mr-2 text-indigo-600" />
                  Datu Vākšana un Izmantošana
                </h2>
                <p>
                  Mēs vācam tikai tos datus, kas nepieciešami mūsu pakalpojumu sniegšanai:
                </p>
                <ul>
                  <li>Kontaktinformācija (vārds, e-pasts, tālrunis)</li>
                  <li>Atrašanās vietas dati (pilsēta)</li>
                  <li>Pārlūkošanas dati un sīkdatnes</li>
                </ul>

                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4 mt-8">
                  <FileText className="w-6 h-6 mr-2 text-indigo-600" />
                  Sīkdatnes
                </h2>
                <p>
                  Mēs izmantojam sīkdatnes, lai uzlabotu jūsu pārlūkošanas pieredzi un 
                  analizētu vietnes lietošanu.
                </p>

                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4 mt-8">
                  <UserCheck className="w-6 h-6 mr-2 text-indigo-600" />
                  Jūsu Tiesības
                </h2>
                <p>
                  Jums ir tiesības:
                </p>
                <ul>
                  <li>Piekļūt saviem datiem</li>
                  <li>Labot savus datus</li>
                  <li>Dzēst savus datus</li>
                  <li>Ierobežot datu apstrādi</li>
                  <li>Iebilst pret datu apstrādi</li>
                </ul>

                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4 mt-8">
                  <Bell className="w-6 h-6 mr-2 text-indigo-600" />
                  Izmaiņas Privātuma Politikā
                </h2>
                <p>
                  Mēs paturam tiesības veikt izmaiņas šajā privātuma politikā. Par būtiskām 
                  izmaiņām mēs informēsim mūsu lietotājus.
                </p>

                <p className="text-sm text-gray-500 mt-8">
                  Pēdējo reizi atjaunināts: 2024. gada 9. novembrī
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}