import React from 'react';
import { categories } from '../data/categories';
import CategoryCard from '../components/CategoryCard';
import { Search, CheckCircle, Clock, ThumbsUp } from 'lucide-react';
import SEOHead from '../components/SEOHead';
import SearchBar from '../components/SearchBar';

export default function HomePage() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://visipakalpojumi.lv",
    "name": "visiPakalpojumi.lv",
    "description": "Atrodiet uzticamus pakalpojumu sniedzējus visā Latvijā. Plašs pakalpojumu klāsts, pārbaudīti speciālisti un reālas atsauksmes.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://visipakalpojumi.lv/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  return (
    <>
      <SEOHead
        title="Pakalpojumu Sniedzēju Katalogs Latvijā"
        description="Atrodiet uzticamus pakalpojumu sniedzējus visā Latvijā. Plašs pakalpojumu klāsts, pārbaudīti speciālisti un reālas atsauksmes."
        canonicalUrl="https://visipakalpojumi.lv"
        schema={schema}
      />

      <main>
        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-r from-indigo-600 to-indigo-800 py-32">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80"
              alt="Profesionāli pakalpojumu sniedzēji Latvijā"
              className="h-full w-full object-cover opacity-10"
            />
          </div>
          
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl mb-4">
                Pakalpojumu Sniedzēju Katalogs Latvijā
              </h1>
              <p className="mx-auto mt-6 max-w-2xl text-xl text-indigo-100">
                Atrodi sev vajadzīgo profesionāli
              </p>
              <div className="mt-10">
                <SearchBar />
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              Mūsu Priekšrocības
            </h2>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
              <div className="flex flex-col items-center text-center p-6">
                <div className="bg-indigo-100 rounded-full p-3 mb-4">
                  <Search className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  Viegli Atrast
                </h3>
                <p className="text-gray-600">
                  Ātri un ērti atrodiet vajadzīgos pakalpojumus jūsu apkaimē
                </p>
              </div>

              <div className="flex flex-col items-center text-center p-6">
                <div className="bg-indigo-100 rounded-full p-3 mb-4">
                  <CheckCircle className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  Pārbaudīti Speciālisti
                </h3>
                <p className="text-gray-600">
                  Uzticami pakalpojumu sniedzēji ar pārbaudītām atsauksmēm
                </p>
              </div>

              <div className="flex flex-col items-center text-center p-6">
                <div className="bg-indigo-100 rounded-full p-3 mb-4">
                  <Clock className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  Ātri Risinājumi
                </h3>
                <p className="text-gray-600">
                  Saņemiet pakalpojumus, kad tie jums nepieciešami
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section className="py-16">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              Populārākās Kategorijas
            </h2>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {categories.map((category) => (
                <CategoryCard key={category.id} category={category} />
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}