import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center">
            <span className="text-xl font-bold" style={{ WebkitTextStroke: '0.5px black' }}>
              <span className="text-[#9E3039]">VISI</span>
              <span className="text-[#FFFFFF]">PAKALPOJUMI</span>
              <span className="text-[#9E3039]">.LV</span>
            </span>
          </Link>
          
          <nav className="hidden md:flex space-x-8">
            <Link 
              to="/" 
              className={`${isActive('/') ? 'text-indigo-600' : 'text-gray-700'} hover:text-indigo-600 transition-colors`}
            >
              Sākums
            </Link>
            <Link 
              to="/par-mums" 
              className={`${isActive('/par-mums') ? 'text-indigo-600' : 'text-gray-700'} hover:text-indigo-600 transition-colors`}
            >
              Par mums
            </Link>
            <Link 
              to="/kontakti" 
              className={`${isActive('/kontakti') ? 'text-indigo-600' : 'text-gray-700'} hover:text-indigo-600 transition-colors`}
            >
              Kontakti
            </Link>
          </nav>

          <button 
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label={isMenuOpen ? 'Aizvērt izvēlni' : 'Atvērt izvēlni'}
          >
            {isMenuOpen ? (
              <X className="w-6 h-6 text-gray-700" />
            ) : (
              <Menu className="w-6 h-6 text-gray-700" />
            )}
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4">
            <div className="flex flex-col space-y-4">
              <Link 
                to="/" 
                className={`${isActive('/') ? 'text-indigo-600' : 'text-gray-700'} hover:text-indigo-600 transition-colors`}
                onClick={() => setIsMenuOpen(false)}
              >
                Sākums
              </Link>
              <Link 
                to="/par-mums" 
                className={`${isActive('/par-mums') ? 'text-indigo-600' : 'text-gray-700'} hover:text-indigo-600 transition-colors`}
                onClick={() => setIsMenuOpen(false)}
              >
                Par mums
              </Link>
              <Link 
                to="/kontakti" 
                className={`${isActive('/kontakti') ? 'text-indigo-600' : 'text-gray-700'} hover:text-indigo-600 transition-colors`}
                onClick={() => setIsMenuOpen(false)}
              >
                Kontakti
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}