import React from 'react';
import { Shield, Users, Clock, ThumbsUp, Award, Target } from 'lucide-react';
import SEOHead from '../components/SEOHead';

export default function AboutPage() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "name": "Par mums | visiPakalpojumi.lv",
    "description": "visiPakalpojumi.lv ir vadošā platforma pakalpojumu sniedzēju meklēšanai Latvijā. Uzziniet vairāk par mūsu misiju un vērtībām.",
    "url": "https://visipakalpojumi.lv/par-mums"
  };

  return (
    <>
      <SEOHead
        title="Par mums"
        description="visiPakalpojumi.lv ir vadošā platforma pakalpojumu sniedzēju meklēšanai Latvijā. Mēs palīdzam atrast uzticamus meistarus un speciālistus visā Latvijā."
        canonicalUrl="https://visipakalpojumi.lv/par-mums"
        schema={schema}
      />

      <div className="bg-white">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80"
              alt="Par mums"
              className="h-full w-full object-cover opacity-10"
            />
          </div>

          <div className="relative max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
                Mēs Padarām Pakalpojumu Meklēšanu Vienkāršu
              </h1>
              <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                visiPakalpojumi.lv ir radīts ar mērķi revolucionizēt veidu, kā cilvēki 
                Latvijā atrod un izvēlas pakalpojumu sniedzējus. Mēs ticam, ka katram ir 
                tiesības uz kvalitatīviem pakalpojumiem par godīgu cenu.
              </p>
            </div>

            <div className="mt-16">
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                  <div className="bg-indigo-50 rounded-lg p-3 inline-block">
                    <Shield className="h-6 w-6 text-indigo-600" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold">Uzticamība</h3>
                  <p className="mt-2 text-gray-600">
                    Mēs rūpīgi pārbaudām katru pakalpojumu sniedzēju, lai nodrošinātu 
                    augstākos kvalitātes standartus.
                  </p>
                </div>

                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                  <div className="bg-indigo-50 rounded-lg p-3 inline-block">
                    <Users className="h-6 w-6 text-indigo-600" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold">Plašs Tīkls</h3>
                  <p className="mt-2 text-gray-600">
                    Mūsu platformā ir apvienoti tūkstošiem profesionāļu no visas 
                    Latvijas dažādās nozarēs.
                  </p>
                </div>

                <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                  <div className="bg-indigo-50 rounded-lg p-3 inline-block">
                    <Clock className="h-6 w-6 text-indigo-600" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold">Ātra Atsaucība</h3>
                  <p className="mt-2 text-gray-600">
                    Mūsu sistēma nodrošina ātru un efektīvu saziņu starp klientiem 
                    un pakalpojumu sniedzējiem.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-16">
              <div className="bg-gradient-to-r from-indigo-50 to-blue-50 rounded-2xl p-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">Mūsu Vērtības</h2>
                <div className="grid gap-6 md:grid-cols-2">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <ThumbsUp className="w-6 h-6 text-indigo-600" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900">Kvalitāte</h3>
                      <p className="mt-1 text-gray-600">
                        Mēs neatkāpjamies no augstākajiem kvalitātes standartiem.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <Award className="w-6 h-6 text-indigo-600" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900">Profesionalitāte</h3>
                      <p className="mt-1 text-gray-600">
                        Mēs strādājam ar labākajiem nozares speciālistiem.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <Target className="w-6 h-6 text-indigo-600" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900">Mērķtiecība</h3>
                      <p className="mt-1 text-gray-600">
                        Mēs nepārtraukti pilnveidojamies un attīstāmies.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <Users className="w-6 h-6 text-indigo-600" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900">Klientu Apmierinātība</h3>
                      <p className="mt-1 text-gray-600">
                        Mūsu prioritāte ir klientu vajadzības un vēlmes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16 text-center">
              <h2 className="text-3xl font-bold text-gray-900">Pievienojieties Mums</h2>
              <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                Vai esat pakalpojumu sniedzējs? Pievienojieties mūsu platformai un 
                sasniedziet jaunus klientus. Mēs palīdzēsim jums augt un attīstīties.
              </p>
              <div className="mt-8">
                <a
                  href="/kontakti"
                  className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Sazināties ar Mums
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}