import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOHeadProps {
  title: string;
  description: string;
  canonicalUrl?: string;
  imageUrl?: string;
  schema?: Record<string, any>;
  keywords?: string[];
  type?: string;
  breadcrumbs?: Array<{ name: string; url: string }>;
}

function truncateTitle(title: string): string {
  return title.length > 60 ? title.substring(0, 57) + '...' : title;
}

function truncateDescription(description: string): string {
  return description.length > 160 ? description.substring(0, 157) + '...' : description;
}

export default function SEOHead({ 
  title, 
  description, 
  canonicalUrl, 
  imageUrl = 'https://visipakalpojumi.lv/og-image.jpg',
  schema,
  keywords = [],
  type = 'website',
  breadcrumbs = []
}: SEOHeadProps) {
  const defaultKeywords = [
    'pakalpojumi latvijā',
    'serviss',
    'meistari',
    'speciālisti',
    'pakalpojumu sniedzēji',
    'uzņēmumi',
    'firmas',
    'profesionāļi',
    ...keywords
  ];

  const truncatedTitle = truncateTitle(`${title} | visiPakalpojumi.lv`);
  const truncatedDescription = truncateDescription(description);

  const defaultSchema = {
    "@context": "https://schema.org",
    "@type": type,
    "name": title,
    "description": truncatedDescription,
    "url": canonicalUrl || 'https://visipakalpojumi.lv',
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://visipakalpojumi.lv/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    }
  };

  const breadcrumbSchema = breadcrumbs.length > 0 ? {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbs.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": item.name,
      "item": item.url
    }))
  } : null;

  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "visiPakalpojumi.lv",
    "url": "https://visipakalpojumi.lv",
    "logo": "https://visipakalpojumi.lv/logo.svg",
    "sameAs": [
      "https://facebook.com/visipakalpojumi",
      "https://instagram.com/visipakalpojumi"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+371-20000000",
      "contactType": "customer service",
      "areaServed": "LV",
      "availableLanguage": ["lv"]
    }
  };

  return (
    <Helmet prioritizeSeoTags={true}>
      <html lang="lv" />
      <title>{truncatedTitle}</title>
      <meta name="description" content={truncatedDescription} />
      <meta name="keywords" content={defaultKeywords.join(', ')} />
      <link rel="canonical" href={canonicalUrl || 'https://visipakalpojumi.lv'} />
      <meta name="author" content="visiPakalpojumi.lv" />

      <meta property="og:type" content={type} />
      <meta property="og:title" content={truncatedTitle} />
      <meta property="og:description" content={truncatedDescription} />
      <meta property="og:url" content={canonicalUrl || 'https://visipakalpojumi.lv'} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="visiPakalpojumi.lv" />
      <meta property="og:locale" content="lv_LV" />
      <meta property="article:publisher" content="https://facebook.com/visipakalpojumi" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@visipakalpojumi" />
      <meta name="twitter:title" content={truncatedTitle} />
      <meta name="twitter:description" content={truncatedDescription} />
      <meta name="twitter:image" content={imageUrl} />

      <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      <meta name="googlebot" content="index, follow" />
      <meta name="language" content="lv" />
      <meta name="geo.region" content="LV" />
      <meta name="geo.placename" content="Latvia" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="theme-color" content="#4F46E5" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content="visiPakalpojumi.lv" />

      <meta httpEquiv="Cache-Control" content="public, max-age=31536000" />
      <meta httpEquiv="Expires" content={new Date(Date.now() + 31536000000).toUTCString()} />

      <script type="application/ld+json">
        {JSON.stringify(schema || defaultSchema)}
      </script>
      {breadcrumbSchema && (
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbSchema)}
        </script>
      )}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
    </Helmet>
  );
}