import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import { cities } from '../data/cities';

interface CityListProps {
  categoryId: string;
}

export default function CityList({ categoryId }: CityListProps) {
  const groupedCities = cities.reduce((acc, city) => {
    if (!acc[city.region]) {
      acc[city.region] = [];
    }
    acc[city.region].push(city);
    return acc;
  }, {} as Record<string, typeof cities>);

  return (
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
      {Object.entries(groupedCities).map(([region, cities]) => (
        <div key={region} className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">{region}</h2>
          <div className="space-y-3">
            {cities.map((city) => (
              <Link
                key={city.id}
                to={`/pakalpojums/${categoryId}/${city.id}`}
                className="flex items-center space-x-2 text-gray-700 hover:text-indigo-600"
              >
                <MapPin className="w-5 h-5" />
                <span>{city.name}</span>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}