import axios from 'axios';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export const sendEmail = async (data: ContactFormData): Promise<boolean> => {
  try {
    const response = await axios.post('https://formspree.io/f/info@visipakalpojumi.lv', {
      ...data,
      _subject: `Jauns ziņojums no ${data.name}`,
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error sending email:', error);
    return false;
  }
};