import React, { useState } from 'react';
import { MapPin, Phone, Globe, Star, ExternalLink, ThumbsUp, MessageCircle } from 'lucide-react';
import { Merchant, Review } from '../types';

interface MerchantCardProps {
  merchant: Merchant;
}

function ReviewCard({ review }: { review: Review }) {
  return (
    <div className="border-t border-gray-100 pt-4 mt-4">
      <div className="flex items-start space-x-3">
        {review.profile_photo_url && (
          <img
            src={review.profile_photo_url}
            alt={review.author_name}
            className="w-8 h-8 rounded-full"
          />
        )}
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <a
              href={review.author_url}
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-gray-900 hover:text-indigo-600"
            >
              {review.author_name}
            </a>
            <span className="text-sm text-gray-500">{review.relative_time_description}</span>
          </div>
          <div className="flex items-center mt-1">
            {[...Array(5)].map((_, i) => (
              <Star
                key={i}
                className={`w-4 h-4 ${
                  i < review.rating
                    ? 'text-yellow-400 fill-current'
                    : 'text-gray-300'
                }`}
              />
            ))}
          </div>
          <p className="mt-2 text-gray-600 text-sm">{review.text}</p>
        </div>
      </div>
    </div>
  );
}

export default function MerchantCard({ merchant }: MerchantCardProps) {
  const [showReviews, setShowReviews] = useState(false);

  return (
    <div className="bg-white rounded-xl shadow-md p-6 hover:shadow-xl transition-all duration-300 border border-gray-100">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold text-gray-900">{merchant.name}</h2>
        {merchant.rating > 0 && (
          <div className="flex items-center space-x-2 bg-indigo-50 px-3 py-1 rounded-full">
            <Star className="w-5 h-5 text-yellow-400 fill-current" />
            <span className="font-medium text-gray-700">{merchant.rating.toFixed(1)}</span>
            <span className="text-sm text-gray-500">({merchant.totalRatings})</span>
          </div>
        )}
      </div>

      <div className="mt-4 space-y-3">
        {merchant.address && (
          <div className="flex items-center space-x-3 text-gray-600 group">
            <div className="bg-gray-50 p-2 rounded-lg group-hover:bg-indigo-50 transition-colors duration-300">
              <MapPin className="w-5 h-5 flex-shrink-0 text-gray-500 group-hover:text-indigo-600" />
            </div>
            <span className="text-sm">{merchant.address}</span>
          </div>
        )}

        {merchant.phone && (
          <div className="flex items-center space-x-3 text-gray-600 group">
            <div className="bg-gray-50 p-2 rounded-lg group-hover:bg-indigo-50 transition-colors duration-300">
              <Phone className="w-5 h-5 flex-shrink-0 text-gray-500 group-hover:text-indigo-600" />
            </div>
            <a 
              href={`tel:${merchant.phone}`} 
              className="text-sm hover:text-indigo-600 transition-colors duration-300"
            >
              {merchant.phone}
            </a>
          </div>
        )}

        {merchant.website && (
          <div className="flex items-center space-x-3 text-gray-600 group">
            <div className="bg-gray-50 p-2 rounded-lg group-hover:bg-indigo-50 transition-colors duration-300">
              <Globe className="w-5 h-5 flex-shrink-0 text-gray-500 group-hover:text-indigo-600" />
            </div>
            <a
              href={merchant.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm hover:text-indigo-600 transition-colors duration-300 flex items-center space-x-1"
            >
              <span>Apmeklēt mājaslapu</span>
              <ExternalLink className="w-4 h-4" />
            </a>
          </div>
        )}
      </div>

      {merchant.reviews && merchant.reviews.length > 0 && (
        <div className="mt-6">
          <button
            onClick={() => setShowReviews(!showReviews)}
            className="flex items-center space-x-2 text-indigo-600 hover:text-indigo-700"
          >
            <MessageCircle className="w-5 h-5" />
            <span>{showReviews ? 'Paslēpt atsauksmes' : 'Skatīt atsauksmes'}</span>
          </button>

          {showReviews && (
            <div className="mt-4">
              {merchant.reviews.slice(0, 3).map((review, index) => (
                <ReviewCard key={index} review={review} />
              ))}
              {merchant.reviews.length > 3 && (
                <a
                  href={`https://www.google.com/maps/place/?q=place_id:${merchant.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 inline-flex items-center text-sm text-indigo-600 hover:text-indigo-700"
                >
                  <span>Skatīt visas atsauksmes Google Maps</span>
                  <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}