import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
      <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex-1 pr-4">
            <p className="text-gray-700">
              Mēs izmantojam sīkdatnes, lai uzlabotu jūsu pārlūkošanas pieredzi un nodrošinātu 
              mūsu vietnes optimālu darbību. Turpinot lietot šo vietni, jūs piekrītat sīkdatņu 
              izmantošanai saskaņā ar mūsu{' '}
              <a href="/privatuma-politika" className="text-indigo-600 hover:text-indigo-800 font-medium">
                privātuma politiku
              </a>
              .
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={acceptCookies}
              className="bg-indigo-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 transition-colors duration-200 font-medium"
            >
              Piekrītu
            </button>
            <button
              onClick={acceptCookies}
              className="text-gray-500 hover:text-gray-700"
              aria-label="Aizvērt paziņojumu"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}