import React from 'react';
import { FileText, Users, AlertCircle, MessageSquare, Scale, Shield } from 'lucide-react';
import SEOHead from '../components/SEOHead';

export default function TermsPage() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Pakalpojuma Noteikumi | visiPakalpojumi.lv",
    "description": "visiPakalpojumi.lv lietošanas noteikumi. Iepazīstieties ar mūsu platformas lietošanas nosacījumiem.",
    "url": "https://visipakalpojumi.lv/lietosanas-noteikumi"
  };

  return (
    <>
      <SEOHead
        title="Pakalpojuma Noteikumi"
        description="visiPakalpojumi.lv lietošanas noteikumi. Iepazīstieties ar mūsu platformas lietošanas nosacījumiem un noteikumiem."
        canonicalUrl="https://visipakalpojumi.lv/lietosanas-noteikumi"
        schema={schema}
      />

      <div className="bg-white">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80"
              alt="Lietošanas noteikumi"
              className="h-full w-full object-cover opacity-10"
            />
          </div>

          <div className="relative max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
                Pakalpojuma Noteikumi
              </h1>
              <p className="mt-4 text-xl text-gray-600">
                Šie noteikumi nosaka visiPakalpojumi.lv vietnes un pakalpojumu izmantošanas kārtību.
              </p>
            </div>

            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
              <div className="grid gap-8 md:grid-cols-2 mb-8">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 bg-indigo-50 rounded-lg p-3">
                    <Scale className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Godīga Lietošana</h3>
                    <p className="mt-1 text-gray-600">
                      Mēs atbalstām godīgu un ētisku platformas izmantošanu.
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 bg-indigo-50 rounded-lg p-3">
                    <Shield className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Drošība</h3>
                    <p className="mt-1 text-gray-600">
                      Mēs rūpējamies par visu lietotāju drošību un privātumu.
                    </p>
                  </div>
                </div>
              </div>

              <div className="prose prose-lg max-w-none">
                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4">
                  <FileText className="w-6 h-6 mr-2 text-indigo-600" />
                  Vispārīgie Noteikumi
                </h2>
                <p>
                  Lietojot mūsu vietni, jūs piekrītat šiem noteikumiem. Ja nepiekrītat 
                  kādam no noteikumiem, lūdzu, pārtrauciet vietnes lietošanu.
                </p>

                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4 mt-8">
                  <Users className="w-6 h-6 mr-2 text-indigo-600" />
                  Pakalpojumu Sniedzēju Atbildība
                </h2>
                <p>
                  Pakalpojumu sniedzēji ir atbildīgi par:
                </p>
                <ul>
                  <li>Sniegtās informācijas patiesumu</li>
                  <li>Pakalpojumu kvalitāti</li>
                  <li>Saistību izpildi pret klientiem</li>
                </ul>

                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4 mt-8">
                  <AlertCircle className="w-6 h-6 mr-2 text-indigo-600" />
                  Lietotāju Atbildība
                </h2>
                <p>
                  Lietotāji apņemas:
                </p>
                <ul>
                  <li>Sniegt patiesu informāciju</li>
                  <li>Ievērot ētikas normas komunikācijā</li>
                  <li>Neveikt ļaunprātīgas darbības</li>
                </ul>

                <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4 mt-8">
                  <MessageSquare className="w-6 h-6 mr-2 text-indigo-600" />
                  Atsauksmju Politika
                </h2>
                <p>
                  Atsauksmes ir svarīga mūsu platformas daļa. Tās jāsniedz godīgi un 
                  objektīvi, balstoties uz reālu pieredzi.
                </p>

                <p className="text-sm text-gray-500 mt-8">
                  Pēdējo reizi atjaunināts: 2024. gada 9. novembrī
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}