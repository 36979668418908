import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import CategoryPage from './pages/CategoryPage';
import CityPage from './pages/CityPage';
import BackToTop from './components/BackToTop';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import CookieConsent from './components/CookieConsent';

// Scroll to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-gray-50 flex flex-col">
          <Header />
          <ScrollToTop />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pakalpojums/:categoryId" element={<CategoryPage />} />
              <Route path="/pakalpojums/:categoryId/:cityId" element={<CityPage />} />
              <Route path="/par-mums" element={<AboutPage />} />
              <Route path="/kontakti" element={<ContactPage />} />
              <Route path="/privatuma-politika" element={<PrivacyPage />} />
              <Route path="/lietosanas-noteikumi" element={<TermsPage />} />
            </Routes>
          </main>
          <BackToTop />
          <Footer />
          <CookieConsent />
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;