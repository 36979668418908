import React from 'react';
import { useParams } from 'react-router-dom';
import { categories } from '../data/categories';
import CityList from '../components/CityList';

export default function CategoryPage() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const category = categories.find((c) => c.id === categoryId);

  if (!category) {
    return <div>Kategorija nav atrasta</div>;
  }

  // Convert category title to genitive case
  const getGenitiveCase = (title: string): string => {
    // Common word endings and their genitive forms
    const endings: Record<string, string> = {
      'šana': 'šanas',
      'nieks': 'nieku',
      'nieki': 'nieku',
      'tājs': 'tāju',
      'tāji': 'tāju',
      'ējs': 'ēju',
      'ēji': 'ēju',
      'is': 'a',
      'e': 'es',
      'centrs': 'centra',
      'serviss': 'servisa',
      'darbi': 'darbu',
      'sistēmas': 'sistēmu',
      'pakalpojumi': 'pakalpojumu',
      'remonts': 'remonta',
      'montāža': 'montāžas',
      'tīrīšana': 'tīrīšanas',
      'kopšana': 'kopšanas',
      'labiekārtošana': 'labiekārtošanas',
      'niecība': 'niecības',
      'ība': 'ības',
      'ārstniecība': 'ārstniecības',
      'ārsts': 'ārsta',
      'ārsti': 'ārstu'
    };

    // Find matching ending and replace it
    for (const [nominative, genitive] of Object.entries(endings)) {
      if (title.toLowerCase().endsWith(nominative)) {
        return title.slice(0, -nominative.length) + genitive;
      }
    }

    // If no specific rule matches, add 's' for genitive
    return title + 's';
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {category.title} Latvijā
          </h1>
          <p className="mt-2 text-gray-600">
            Izvēlieties pilsētu, lai atrastu {getGenitiveCase(category.title.toLowerCase())} pakalpojumus jūsu apkaimē
          </p>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
        <CityList categoryId={categoryId!} />
      </div>
    </div>
  );
}