import { useState, useEffect } from 'react';
import { searchPlaces } from '../services/placesService';
import { Merchant } from '../types';

export function usePlaces(categoryId: string, city: string) {
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPlaces = async () => {
      if (!categoryId || !city) return;
      
      try {
        setLoading(true);
        setError(null);
        const results = await searchPlaces(categoryId, city);
        setMerchants(results);
      } catch (err) {
        setError('Neizdevās ielādēt pakalpojumu sniedzējus. Lūdzu, mēģiniet vēlāk.');
        console.error('Error loading places:', err);
      } finally {
        setLoading(false);
      }
    };

    loadPlaces();
  }, [categoryId, city]);

  return { merchants, loading, error };
}