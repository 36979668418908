import { CategoryConfig } from '../types/search';

const commonExclusions = [
  'veikals', 'shop', 'store', 'preces', 'apbedīšana', 'taxi', 'pakomāts',
  'salons', 'tirdzniecība', 'noliktava', 'vairumtirdzniecība'
];

export const configs: Record<string, CategoryConfig> = {
  // Home Services
  'santehniki': {
    searchTerms: ['santehniķis', 'santehnikas darbi', 'santehnikas meistars'],
    strictSearchTerms: ['santehniķis', 'santehnikas meistars'],
    requiredKeywords: ['santehni', 'ūdens', 'kanalizācij'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/santehni[kķ]/i, /[uū]densvad/i],
    businessTypes: ['plumber', 'point_of_interest'],
    requiredTypes: ['plumber']
  },
  'elektriki': {
    searchTerms: ['elektriķis', 'elektriķa pakalpojumi', 'elektroinstalācija'],
    strictSearchTerms: ['elektriķis', 'elektromontāža'],
    requiredKeywords: ['elektri', 'elektro'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/elektri[kķ]/i, /elektromont[āa][žz]/i],
    businessTypes: ['electrician', 'point_of_interest'],
    requiredTypes: ['electrician']
  },
  'apkure': {
    searchTerms: ['apkures sistēmas', 'apkures montāža', 'siltumtehnika'],
    strictSearchTerms: ['apkures sistēmas', 'siltumtehnika'],
    requiredKeywords: ['apkur', 'siltumtehn'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/apkur[ei]/i, /siltumtehn/i],
    businessTypes: ['hvac_contractor', 'point_of_interest'],
    requiredTypes: ['hvac_contractor']
  },
  'remontdarbi': {
    searchTerms: ['remontdarbi', 'dzīvokļu remonts', 'māju remonts'],
    strictSearchTerms: ['remontdarbi', 'būvdarbi'],
    requiredKeywords: ['remont', 'būvdarb', 'celtniec'],
    excludeKeywords: [...commonExclusions, 'auto'],
    namePatterns: [/remont/i, /būvdarb/i, /celtniec/i],
    businessTypes: ['general_contractor', 'point_of_interest'],
    requiredTypes: ['general_contractor']
  },
  'uzkopsana': {
    searchTerms: ['telpu uzkopšana', 'māju tīrīšana', 'biroju uzkopšana'],
    strictSearchTerms: ['telpu uzkopšana', 'tīrīšanas serviss'],
    requiredKeywords: ['uzkop', 'tīrīšan'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/uzkop/i, /tīrīšan/i],
    businessTypes: ['cleaning_service', 'point_of_interest'],
    requiredTypes: ['cleaning_service']
  },

  // Construction & Repair
  'buvnieciba': {
    searchTerms: ['būvniecība', 'celtniecība', 'būvdarbi'],
    strictSearchTerms: ['būvniecība', 'celtniecība'],
    requiredKeywords: ['būvniec', 'celtniec'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/būvniec/i, /celtniec/i],
    businessTypes: ['general_contractor', 'point_of_interest'],
    requiredTypes: ['general_contractor']
  },
  'jumti': {
    searchTerms: ['jumtu darbi', 'jumtu remonts', 'jumtu segumi'],
    strictSearchTerms: ['jumtu darbi', 'jumtu meistars'],
    requiredKeywords: ['jumt'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/jumt/i],
    businessTypes: ['roofing_contractor', 'point_of_interest'],
    requiredTypes: ['roofing_contractor']
  },
  'logi-durvis': {
    searchTerms: ['logu montāža', 'durvju uzstādīšana', 'logu meistars'],
    strictSearchTerms: ['logu montāža', 'durvju uzstādīšana'],
    requiredKeywords: ['log', 'durv'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/log/i, /durv/i],
    businessTypes: ['window_contractor', 'point_of_interest'],
    requiredTypes: ['window_contractor']
  },
  'mebeles': {
    searchTerms: ['mēbeļu izgatavošana', 'mēbeļu dizains', 'mēbeles pēc pasūtījuma'],
    strictSearchTerms: ['mēbeļu izgatavošana', 'mēbeles pēc pasūtījuma'],
    requiredKeywords: ['mēbel'],
    excludeKeywords: [...commonExclusions, 'veikals', 'salons'],
    namePatterns: [/mēbel/i],
    businessTypes: ['furniture_store', 'point_of_interest'],
    requiredTypes: ['furniture_store']
  },
  'galdnieki': {
    searchTerms: ['galdnieks', 'galdniecības pakalpojumi', 'koka mēbeles'],
    strictSearchTerms: ['galdnieks', 'galdniecība'],
    requiredKeywords: ['galdniec', 'galdnieks'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/galdnie[ck]/i],
    businessTypes: ['carpenter', 'point_of_interest'],
    requiredTypes: ['carpenter']
  },

  // Auto Services
  'autoserviss': {
    searchTerms: ['autoserviss', 'auto remonts', 'auto apkope'],
    strictSearchTerms: ['autoserviss', 'auto remonts'],
    requiredKeywords: ['autoservis', 'auto'],
    excludeKeywords: [...commonExclusions, 'veikals', 'noma'],
    namePatterns: [/autoservis/i, /auto[ -]remont/i],
    businessTypes: ['car_repair', 'point_of_interest'],
    requiredTypes: ['car_repair']
  },
  'automazgasana': {
    searchTerms: ['automazgātava', 'auto mazgāšana', 'auto tīrīšana'],
    strictSearchTerms: ['automazgātava', 'auto mazgāšana'],
    requiredKeywords: ['automazgā', 'mazgā'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/automazg[āa]/i, /auto[ -]mazg[āa]/i],
    businessTypes: ['car_wash', 'point_of_interest'],
    requiredTypes: ['car_wash']
  },
  'evakuators': {
    searchTerms: ['evakuators', 'auto evakuācija', 'evakuācijas pakalpojumi'],
    strictSearchTerms: ['evakuators', 'auto evakuācija'],
    requiredKeywords: ['evaku'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/evaku[āa]/i],
    businessTypes: ['car_repair', 'point_of_interest'],
    requiredTypes: ['car_repair']
  },
  'riepuserviss': {
    searchTerms: ['riepu serviss', 'riepu montāža', 'riepu maiņa'],
    strictSearchTerms: ['riepu serviss', 'riepu montāža'],
    requiredKeywords: ['riep'],
    excludeKeywords: [...commonExclusions, 'veikals'],
    namePatterns: [/riep[ua]/i],
    businessTypes: ['car_repair', 'point_of_interest'],
    requiredTypes: ['car_repair']
  },

  // Professional Services
  'juristi': {
    searchTerms: ['jurists', 'advokāts', 'juridiskais birojs'],
    strictSearchTerms: ['jurists', 'advokāts'],
    requiredKeywords: ['juris', 'advok', 'ties'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/juris/i, /advok[āa]t/i],
    businessTypes: ['lawyer', 'point_of_interest'],
    requiredTypes: ['lawyer']
  },
  'gramatvediba': {
    searchTerms: ['grāmatvedība', 'grāmatvedības pakalpojumi', 'grāmatvedis'],
    strictSearchTerms: ['grāmatvedība', 'grāmatvedis'],
    requiredKeywords: ['grāmatved'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/gr[āa]matved/i],
    businessTypes: ['accounting', 'point_of_interest'],
    requiredTypes: ['accounting']
  },
  'tulkosana': {
    searchTerms: ['tulkošanas birojs', 'tulkošanas pakalpojumi', 'tulks'],
    strictSearchTerms: ['tulkošanas birojs', 'tulks'],
    requiredKeywords: ['tulko'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/tulko/i],
    businessTypes: ['translator', 'point_of_interest'],
    requiredTypes: ['translator']
  },
  'nekustamie-ipasumi': {
    searchTerms: ['nekustamie īpašumi', 'īpašumu pārvaldība', 'mākleri'],
    strictSearchTerms: ['nekustamie īpašumi', 'īpašumu pārvaldība'],
    requiredKeywords: ['nekustam', 'īpašum'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/nekustam/i, /[īi]pa[šs]um/i],
    businessTypes: ['real_estate_agency', 'point_of_interest'],
    requiredTypes: ['real_estate_agency']
  },
  'apdrosinasana': {
    searchTerms: ['apdrošināšana', 'apdrošināšanas pakalpojumi'],
    strictSearchTerms: ['apdrošināšana'],
    requiredKeywords: ['apdroš'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/apdro[šs]/i],
    businessTypes: ['insurance_agency', 'point_of_interest'],
    requiredTypes: ['insurance_agency']
  },

  // Beauty & Health
  'skaistumkopsana': {
    searchTerms: ['skaistumkopšana', 'frizieris', 'kosmetologs'],
    strictSearchTerms: ['skaistumkopšana', 'skaistuma salons'],
    requiredKeywords: ['skaistum', 'salon'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/skaistum/i, /salon/i],
    businessTypes: ['beauty_salon', 'point_of_interest'],
    requiredTypes: ['beauty_salon']
  },
  'masaza': {
    searchTerms: ['masāža', 'masāžas pakalpojumi', 'masieris'],
    strictSearchTerms: ['masāža', 'masieris'],
    requiredKeywords: ['masā', 'masie'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/mas[āa][žz]/i, /masier/i],
    businessTypes: ['spa', 'point_of_interest'],
    requiredTypes: ['spa']
  },
  'spa': {
    searchTerms: ['spa centrs', 'spa procedūras', 'spa salons'],
    strictSearchTerms: ['spa centrs', 'spa salons'],
    requiredKeywords: ['spa'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/spa/i],
    businessTypes: ['spa', 'point_of_interest'],
    requiredTypes: ['spa']
  },
  'zobarstnieciba': {
    searchTerms: ['zobārstniecība', 'zobārsts', 'zobu ārstēšana'],
    strictSearchTerms: ['zobārstniecība', 'zobārsts'],
    requiredKeywords: ['zob'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/zob/i],
    businessTypes: ['dentist', 'point_of_interest'],
    requiredTypes: ['dentist']
  },
  'veterinarija': {
    searchTerms: ['veterinārārsts', 'veterinārā klīnika', 'veterinārie pakalpojumi'],
    strictSearchTerms: ['veterinārārsts', 'veterinārā klīnika'],
    requiredKeywords: ['veterin'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/veterin/i],
    businessTypes: ['veterinary_care', 'point_of_interest'],
    requiredTypes: ['veterinary_care']
  },

  // Transport & Logistics
  'parvadajumi': {
    searchTerms: ['kravu pārvadājumi', 'transporta pakalpojumi', 'pārvadājumi'],
    strictSearchTerms: ['kravu pārvadājumi', 'pārvadājumi'],
    requiredKeywords: ['pārvadā', 'krav'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/p[āa]rvad[āa]/i, /krav[au]/i],
    businessTypes: ['moving_company', 'point_of_interest'],
    requiredTypes: ['moving_company']
  },
  'parvaksanas': {
    searchTerms: ['pārvākšanās serviss', 'mēbeļu pārvadāšana', 'pārvākšanās'],
    strictSearchTerms: ['pārvākšanās serviss', 'pārvākšanās'],
    requiredKeywords: ['pārvāk'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/p[āa]rv[āa]k/i],
    businessTypes: ['moving_company', 'point_of_interest'],
    requiredTypes: ['moving_company']
  },
  'kurjeri': {
    searchTerms: ['kurjeru pakalpojumi', 'sūtījumu piegāde', 'kurjers'],
    strictSearchTerms: ['kurjeru pakalpojumi', 'kurjers'],
    requiredKeywords: ['kurjer', 'piegād'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/kurjer/i, /pieg[āa]d/i],
    businessTypes: ['courier', 'point_of_interest'],
    requiredTypes: ['courier']
  },
  'noma': {
    searchTerms: ['auto noma', 'transporta noma', 'nomas pakalpojumi'],
    strictSearchTerms: ['auto noma', 'transporta noma'],
    requiredKeywords: ['noma'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/noma/i],
    businessTypes: ['car_rental', 'point_of_interest'],
    requiredTypes: ['car_rental']
  },

  // Security & Safety
  'apsardze': {
    searchTerms: ['apsardze', 'apsardzes pakalpojumi', 'drošības dienests'],
    strictSearchTerms: ['apsardze', 'apsardzes firma'],
    requiredKeywords: ['apsardz'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/apsardz/i],
    businessTypes: ['security_system', 'point_of_interest'],
    requiredTypes: ['security_system']
  },
  'ugunsdzesiba': {
    searchTerms: ['ugunsdrošība', 'ugunsdzēsības sistēmas', 'ugunsdrošības pakalpojumi'],
    strictSearchTerms: ['ugunsdrošība', 'ugunsdzēsība'],
    requiredKeywords: ['uguns'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/uguns/i],
    businessTypes: ['fire_station', 'point_of_interest'],
    requiredTypes: ['fire_station']
  },
  'atsledznieki': {
    searchTerms: ['atslēdznieks', 'atslēgu serviss', 'durvju atvēršana'],
    strictSearchTerms: ['atslēdznieks', 'atslēgu serviss'],
    requiredKeywords: ['atslēg'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/atsl[ēe]g/i],
    businessTypes: ['locksmith', 'point_of_interest'],
    requiredTypes: ['locksmith']
  },
  'signalizacija': {
    searchTerms: ['signalizācijas sistēmas', 'drošības sistēmas', 'signalizācijas uzstādīšana'],
    strictSearchTerms: ['signalizācijas sistēmas', 'drošības sistēmas'],
    requiredKeywords: ['signaliz', 'drošīb'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/signaliz/i, /dro[šs][īi]b/i],
    businessTypes: ['security_system', 'point_of_interest'],
    requiredTypes: ['security_system']
  },

  // IT & Electronics
  'datori': {
    searchTerms: ['datoru serviss', 'datoru remonts', 'datoru apkope'],
    strictSearchTerms: ['datoru serviss', 'datoru remonts'],
    requiredKeywords: ['dator'],
    excludeKeywords: [...commonExclusions, 'veikals'],
    namePatterns: [/dator/i],
    businessTypes: ['electronics_store', 'point_of_interest'],
    requiredTypes: ['electronics_store']
  },
  'mobilie': {
    searchTerms: ['mobilo telefonu remonts', 'telefonu serviss', 'viedtālruņu remonts'],
    strictSearchTerms: ['mobilo telefonu remonts', 'telefonu serviss'],
    requiredKeywords: ['telefon', 'mobil'],
    excludeKeywords: [...commonExclusions, 'veikals'],
    namePatterns: [/telefon/i, /mobil/i],
    businessTypes: ['electronics_store', 'point_of_interest'],
    requiredTypes: ['electronics_store']
  },
  'internets': {
    searchTerms: ['interneta pakalpojumi', 'interneta pieslēgums', 'interneta uzstādīšana'],
    strictSearchTerms: ['interneta pakalpojumi', 'interneta pieslēgums'],
    requiredKeywords: ['internet'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/internet/i],
    businessTypes: ['internet_cafe', 'point_of_interest'],
    requiredTypes: ['internet_cafe']
  },
  'majaslapa': {
    searchTerms: ['mājaslapu izstrāde', 'web dizains', 'programmēšana'],
    strictSearchTerms: ['mājaslapu izstrāde', 'web dizains'],
    requiredKeywords: ['mājaslap', 'web', 'dizain'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/m[āa]jaslap/i, /web/i],
    businessTypes: ['web_design', 'point_of_interest'],
    requiredTypes: ['web_design']
  },

  // Household Services
  'mebelu-remonts': {
    searchTerms: ['mēbeļu remonts', 'mēbeļu restaurācija', 'mēbeļu labošana'],
    strictSearchTerms: ['mēbeļu remonts', 'mēbeļu restaurācija'],
    requiredKeywords: ['mēbel'],
    excludeKeywords: [...commonExclusions, 'veikals'],
    namePatterns: [/m[ēe]be[ļl]/i],
    businessTypes: ['furniture_store', 'point_of_interest'],
    requiredTypes: ['furniture_store']
  },
  'sadzives-tehnika': {
    searchTerms: ['sadzīves tehnikas remonts', 'elektrotehnikas remonts', 'tehnikas serviss'],
    strictSearchTerms: ['sadzīves tehnikas remonts', 'elektrotehnikas remonts'],
    requiredKeywords: ['sadzīves', 'tehnik'],
    excludeKeywords: [...commonExclusions, 'veikals'],
    namePatterns: [/sadz[īi]ves/i, /tehni[kķ]/i],
    businessTypes: ['electronics_store', 'point_of_interest'],
    requiredTypes: ['electronics_store']
  },
  'dezinfekcija': {
    searchTerms: ['dezinfekcija', 'kaitēkļu iznīcināšana', 'dezinsekcija'],
    strictSearchTerms: ['dezinfekcija', 'kaitēkļu iznīcināšana'],
    requiredKeywords: ['dezinfek', 'kaitēk'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/dezinfek/i, /kait[ēe]k/i],
    businessTypes: ['pest_control', 'point_of_interest'],
    requiredTypes: ['pest_control']
  },
  'chimistirija': {
    searchTerms: ['ķīmiskā tīrītava', 'veļas mazgātava', 'tīrīšana'],
    strictSearchTerms: ['ķīmiskā tīrītava', 'veļas mazgātava'],
    requiredKeywords: ['ķīmisk', 'tīrītav'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/[ķk][īi]misk/i, /t[īi]r[īi]tav/i],
    businessTypes: ['laundry', 'point_of_interest'],
    requiredTypes: ['laundry']
  },

  // Outdoor Services
  'darznieki': {
    searchTerms: ['dārznieks', 'dārza kopšana', 'dārza labiekārtošana'],
    strictSearchTerms: ['dārznieks', 'dārza kopšana'],
    requiredKeywords: ['dārz'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/d[āa]rz/i],
    businessTypes: ['landscaping', 'point_of_interest'],
    requiredTypes: ['landscaping']
  },
  'teritorijas-uzkopsana': {
    searchTerms: ['teritorijas uzkopšana', 'teritorijas tīrīšana', 'sniega tīrīšana'],
    strictSearchTerms: ['teritorijas uzkopšana', 'teritorijas tīrīšana'],
    requiredKeywords: ['uzkop', 'tīrīšan'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/uzkop/i, /t[īi]r[īi][šs]an/i],
    businessTypes: ['cleaning_service', 'point_of_interest'],
    requiredTypes: ['cleaning_service']
  },
  'zemes-darbi': {
    searchTerms: ['zemes darbi', 'rakšanas darbi', 'planēšanas darbi'],
    strictSearchTerms: ['zemes darbi', 'rakšanas darbi'],
    requiredKeywords: ['zemes', 'rakšan'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/zemes/i, /rak[šs]an/i],
    businessTypes: ['excavating_contractor', 'point_of_interest'],
    requiredTypes: ['excavating_contractor']
  },
  'labiekartosana': {
    searchTerms: ['teritorijas labiekārtošana', 'apzaļumošana', 'bruģēšana'],
    strictSearchTerms: ['teritorijas labiekārtošana', 'labiekārtošana'],
    requiredKeywords: ['labiekārt', 'apzaļum'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/labiek[āa]rt/i, /apza[ļl]um/i],
    businessTypes: ['landscaping', 'point_of_interest'],
    requiredTypes: ['landscaping']
  },

  // Education & Training
  'valodu-kursi': {
    searchTerms: ['valodu kursi', 'valodu skola', 'valodu apmācība'],
    strictSearchTerms: ['valodu kursi', 'valodu skola'],
    requiredKeywords: ['valod', 'kurs'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/valod/i, /kurs/i],
    businessTypes: ['school', 'point_of_interest'],
    requiredTypes: ['school']
  },
  'autoskola': {
    searchTerms: ['autoskola', 'vadītāju apmācība', 'braukšanas kursi'],
    strictSearchTerms: ['autoskola', 'vadītāju apmācība'],
    requiredKeywords: ['autoskol'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/autoskol/i],
    businessTypes: ['driving_school', 'point_of_interest'],
    requiredTypes: ['driving_school']
  },
  'sports': {
    searchTerms: ['sporta zāle', 'fitnesa centrs', 'sporta klubs'],
    strictSearchTerms: ['sporta zāle', 'fitnesa centrs'],
    requiredKeywords: ['sport', 'fitnes'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/sport/i, /fitnes/i],
    businessTypes: ['gym', 'point_of_interest'],
    requiredTypes: ['gym']
  },

  // Entertainment & Events
  'fotografs': {
    searchTerms: ['fotogrāfs', 'foto pakalpojumi', 'fotostudija'],
    strictSearchTerms: ['fotogrāfs', 'fotostudija'],
    requiredKeywords: ['foto'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/foto/i],
    businessTypes: ['photographer', 'point_of_interest'],
    requiredTypes: ['photographer']
  },
  'svetku-agenturas': {
    searchTerms: ['svētku aģentūra', 'pasākumu organizēšana', 'svētku organizēšana'],
    strictSearchTerms: ['svētku aģentūra', 'pasākumu organizēšana'],
    requiredKeywords: ['svētk', 'pasākum'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/sv[ēe]tk/i, /pas[āa]kum/i],
    businessTypes: ['event_planner', 'point_of_interest'],
    requiredTypes: ['event_planner']
  },
  'muzika': {
    searchTerms: ['muzikanti', 'mūziķi', 'muzikālie pakalpojumi'],
    strictSearchTerms: ['muzikanti', 'mūziķi'],
    requiredKeywords: ['muzik', 'mūzik'],
    excludeKeywords: [...commonExclusions],
    namePatterns: [/muzik/i, /m[ūu]zi[kķ]/i],
    businessTypes: ['musician', 'point_of_interest'],
    requiredTypes: ['musician']
  }
};

export function getConfig(categoryId: string): CategoryConfig | null {
  if (!categoryId) return null;
  
  const config = configs[categoryId.toLowerCase()];
  if (!config) {
    console.error('Category config not found:', categoryId);
    return null;
  }
  return config;
}