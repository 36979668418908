import { City } from '../types';

export const cities: City[] = [
  // Republikas pilsētas
  { id: 'riga', name: 'Rīga', locative: 'Rīgā', region: 'Rīgas reģions' },
  { id: 'daugavpils', name: 'Daugavpils', locative: 'Daugavpilī', region: 'Latgale' },
  { id: 'jelgava', name: 'Jelgava', locative: 'Jelgavā', region: 'Zemgale' },
  { id: 'jurmala', name: 'Jūrmala', locative: 'Jūrmalā', region: 'Rīgas reģions' },
  { id: 'liepaja', name: 'Liepāja', locative: 'Liepājā', region: 'Kurzeme' },
  { id: 'rezekne', name: 'Rēzekne', locative: 'Rēzeknē', region: 'Latgale' },
  { id: 'valmiera', name: 'Valmiera', locative: 'Valmierā', region: 'Vidzeme' },
  { id: 'ventspils', name: 'Ventspils', locative: 'Ventspilī', region: 'Kurzeme' },
  
  // Rīgas reģions
  { id: 'salaspils', name: 'Salaspils', locative: 'Salaspilī', region: 'Rīgas reģions' },
  { id: 'olaine', name: 'Olaine', locative: 'Olainē', region: 'Rīgas reģions' },
  { id: 'ogre', name: 'Ogre', locative: 'Ogrē', region: 'Rīgas reģions' },
  { id: 'sigulda', name: 'Sigulda', locative: 'Siguldā', region: 'Rīgas reģions' },
  { id: 'tukums', name: 'Tukums', locative: 'Tukumā', region: 'Rīgas reģions' },
  
  // Vidzeme
  { id: 'cesis', name: 'Cēsis', locative: 'Cēsīs', region: 'Vidzeme' },
  { id: 'smiltene', name: 'Smiltene', locative: 'Smiltenē', region: 'Vidzeme' },
  { id: 'madona', name: 'Madona', locative: 'Madonā', region: 'Vidzeme' },
  { id: 'aluksne', name: 'Alūksne', locative: 'Alūksnē', region: 'Vidzeme' },
  { id: 'gulbene', name: 'Gulbene', locative: 'Gulbenē', region: 'Vidzeme' },
  
  // Kurzeme
  { id: 'kuldiga', name: 'Kuldīga', locative: 'Kuldīgā', region: 'Kurzeme' },
  { id: 'talsi', name: 'Talsi', locative: 'Talsos', region: 'Kurzeme' },
  { id: 'saldus', name: 'Saldus', locative: 'Saldū', region: 'Kurzeme' },
  
  // Zemgale
  { id: 'bauska', name: 'Bauska', locative: 'Bauskā', region: 'Zemgale' },
  { id: 'dobele', name: 'Dobele', locative: 'Dobelē', region: 'Zemgale' },
  { id: 'aizkraukle', name: 'Aizkraukle', locative: 'Aizkrauklē', region: 'Zemgale' },
  { id: 'jaunjelgava', name: 'Jaunjelgava', locative: 'Jaunjelgavā', region: 'Zemgale' },
  
  // Latgale
  { id: 'kraslava', name: 'Krāslava', locative: 'Krāslavā', region: 'Latgale' },
  { id: 'ludza', name: 'Ludza', locative: 'Ludzā', region: 'Latgale' },
  { id: 'preili', name: 'Preiļi', locative: 'Preiļos', region: 'Latgale' },
  { id: 'balvi', name: 'Balvi', locative: 'Balvos', region: 'Latgale' },
  { id: 'livani', name: 'Līvāni', locative: 'Līvānos', region: 'Latgale' },
];