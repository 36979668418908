import { initGoogleMaps, getLatLngFromCity } from '../utils/googleMaps';
import { Merchant } from '../types';
import { getConfig } from './categoryConfigs';
import { validateResult } from './searchFilters';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
const MAX_RETRIES = 3;
const MAX_RESULTS = 15;
const SEARCH_RADIUS = 15000; // 15km radius

export const searchPlaces = async (categoryId: string, city: string): Promise<Merchant[]> => {
  let retries = 0;
  const allResults: google.maps.places.PlaceResult[] = [];
  
  while (retries < MAX_RETRIES) {
    try {
      const config = getConfig(categoryId);
      if (!config) {
        console.error('Invalid category:', categoryId);
        return [];
      }

      const google = await initGoogleMaps();
      const location = await getLatLngFromCity(city);
      
      let mapContainer = document.getElementById('map-container');
      if (!mapContainer) {
        mapContainer = document.createElement('div');
        mapContainer.id = 'map-container';
        mapContainer.style.visibility = 'hidden';
        mapContainer.style.position = 'absolute';
        mapContainer.style.width = '100px';
        mapContainer.style.height = '100px';
        document.body.appendChild(mapContainer);
      }

      const map = new google.maps.Map(mapContainer, {
        center: location,
        zoom: 15
      });

      const service = new google.maps.places.PlacesService(map);

      // First search: Nearby search with strict terms
      for (const term of config.strictSearchTerms) {
        const nearbyResults = await new Promise<google.maps.places.PlaceResult[]>((resolve) => {
          service.nearbySearch({
            location,
            radius: SEARCH_RADIUS,
            keyword: term,
            language: 'lv'
          }, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              resolve([]);
            }
          });
        });
        
        allResults.push(...nearbyResults);
        await delay(300);
      }

      // Second search: Text search with all terms
      for (const term of [...config.strictSearchTerms, ...config.searchTerms]) {
        if (allResults.length >= MAX_RESULTS * 3) break;

        const textResults = await new Promise<google.maps.places.PlaceResult[]>((resolve) => {
          service.textSearch({
            query: `${term} ${city}`,
            language: 'lv',
            region: 'lv'
          }, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              resolve([]);
            }
          });
        });

        allResults.push(...textResults);
        await delay(300);
      }

      // Remove duplicates and filter by location
      const uniqueResults = Array.from(
        new Map(allResults.map(place => [place.place_id, place])).values()
      ).filter(place => {
        if (!place.geometry?.location) return false;
        
        try {
          const distance = google.maps.geometry.spherical.computeDistanceBetween(
            place.geometry.location,
            new google.maps.LatLng(location.lat, location.lng)
          );
          
          return distance <= SEARCH_RADIUS;
        } catch (error) {
          console.error('Error calculating distance:', error);
          return false;
        }
      });

      // Filter results using category config
      const filteredResults = uniqueResults
        .filter(place => validateResult(place, config))
        .slice(0, MAX_RESULTS);

      // Get details for each filtered place
      const detailedResults = await Promise.all(
        filteredResults.map(async (place) => {
          if (!place.place_id) return null;

          return new Promise<google.maps.places.PlaceResult>((resolve) => {
            service.getDetails({
              placeId: place.place_id!,
              fields: [
                'name',
                'formatted_address',
                'rating',
                'user_ratings_total',
                'geometry',
                'website',
                'formatted_phone_number',
                'opening_hours',
                'business_status',
                'reviews'
              ]
            }, (result, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && result) {
                resolve(result);
              } else {
                resolve(place);
              }
            });
          });
        })
      );

      const merchants: Merchant[] = detailedResults
        .filter((place): place is google.maps.places.PlaceResult => 
          place !== null && 
          place.geometry?.location !== undefined &&
          place.business_status !== 'PERMANENTLY_CLOSED'
        )
        .map(place => ({
          id: place.place_id || `temp-${Math.random()}`,
          name: place.name || 'Nezināms uzņēmums',
          address: place.formatted_address || place.vicinity || 'Nav norādīta adrese',
          rating: place.rating || 0,
          totalRatings: place.user_ratings_total || 0,
          location: {
            lat: place.geometry!.location!.lat(),
            lng: place.geometry!.location!.lng()
          },
          phone: place.formatted_phone_number || '',
          website: place.website || '',
          reviews: place.reviews?.map(review => ({
            author_name: review.author_name,
            author_url: review.author_url,
            profile_photo_url: review.profile_photo_url,
            rating: review.rating,
            relative_time_description: review.relative_time_description,
            text: review.text,
            time: review.time
          }))
        }))
        .sort((a, b) => (b.rating * (b.totalRatings || 1)) - (a.rating * (a.totalRatings || 1)));

      return merchants;

    } catch (error) {
      console.error('Search attempt failed:', error);
      retries++;
      if (retries === MAX_RETRIES) {
        console.error('All retries failed in searchPlaces');
        return [];
      }
      await delay(1000 * retries);
      console.log(`Retrying search (attempt ${retries + 1}/${MAX_RETRIES})...`);
    }
  }

  return [];
}