import { ServiceCategory } from '../types';

export const categories: ServiceCategory[] = [
  // Home Services
  {
    id: 'santehniki',
    title: 'Santehniķi',
    icon: 'Wrench',
    description: 'Santehnikas uzstādīšana un remonts',
  },
  {
    id: 'elektriki',
    title: 'Elektriķi',
    icon: 'Zap',
    description: 'Elektroinstalācijas darbi un remonts',
  },
  {
    id: 'apkure',
    title: 'Apkures Sistēmas',
    icon: 'Flame',
    description: 'Apkures sistēmu uzstādīšana un apkope',
  },
  {
    id: 'remontdarbi',
    title: 'Remontdarbi',
    icon: 'Hammer',
    description: 'Dzīvokļu un māju remonts',
  },
  {
    id: 'uzkopsana',
    title: 'Telpu Uzkopšana',
    icon: 'Sparkles',
    description: 'Māju un biroju tīrīšana',
  },

  // Construction & Repair
  {
    id: 'buvnieciba',
    title: 'Būvniecība',
    icon: 'Building2',
    description: 'Ēku būvniecība un rekonstrukcija',
  },
  {
    id: 'jumti',
    title: 'Jumtu Darbi',
    icon: 'Home',
    description: 'Jumtu izbūve un remonts',
  },
  {
    id: 'logi-durvis',
    title: 'Logi un Durvis',
    icon: 'LayoutGrid',
    description: 'Logu un durvju uzstādīšana',
  },
  {
    id: 'mebeles',
    title: 'Mēbeļu Izgatavošana',
    icon: 'Sofa',
    description: 'Mēbeļu izgatavošana pēc pasūtījuma',
  },
  {
    id: 'galdnieki',
    title: 'Galdnieki',
    icon: 'Hammer',
    description: 'Galdniecības pakalpojumi',
  },

  // Auto Services
  {
    id: 'autoserviss',
    title: 'Autoserviss',
    icon: 'Car',
    description: 'Auto remonts un apkope',
  },
  {
    id: 'automazgasana',
    title: 'Automazgātava',
    icon: 'Droplets',
    description: 'Auto mazgāšana un tīrīšana',
  },
  {
    id: 'evakuators',
    title: 'Evakuators',
    icon: 'Truck',
    description: 'Auto evakuācijas pakalpojumi',
  },
  {
    id: 'riepuserviss',
    title: 'Riepu Serviss',
    icon: 'CircleDot',
    description: 'Riepu montāža un balansēšana',
  },

  // Professional Services
  {
    id: 'juristi',
    title: 'Juridiskie Pakalpojumi',
    icon: 'Scale',
    description: 'Juridiskā palīdzība un konsultācijas',
  },
  {
    id: 'gramatvediba',
    title: 'Grāmatvedība',
    icon: 'Calculator',
    description: 'Grāmatvedības pakalpojumi',
  },
  {
    id: 'tulkosana',
    title: 'Tulkošanas Biroji',
    icon: 'Languages',
    description: 'Dokumentu tulkošana',
  },
  {
    id: 'nekustamie-ipasumi',
    title: 'Nekustamie Īpašumi',
    icon: 'Building',
    description: 'Nekustamo īpašumu pakalpojumi',
  },
  {
    id: 'apdrosinasana',
    title: 'Apdrošināšana',
    icon: 'Shield',
    description: 'Apdrošināšanas pakalpojumi',
  },

  // Beauty & Health
  {
    id: 'skaistumkopsana',
    title: 'Skaistumkopšana',
    icon: 'Scissors',
    description: 'Frizieri un kosmetologi',
  },
  {
    id: 'masaza',
    title: 'Masāža',
    icon: 'Heart',
    description: 'Masāžas pakalpojumi',
  },
  {
    id: 'spa',
    title: 'SPA Centri',
    icon: 'Flower2',
    description: 'SPA procedūras',
  },
  {
    id: 'zobarstnieciba',
    title: 'Zobārstniecība',
    icon: 'Stethoscope',
    description: 'Zobārstniecības pakalpojumi',
  },
  {
    id: 'veterinarija',
    title: 'Veterinārija',
    icon: 'Cat',
    description: 'Veterinārie pakalpojumi',
  },

  // Transport & Logistics
  {
    id: 'parvadajumi',
    title: 'Kravu Pārvadājumi',
    icon: 'Truck',
    description: 'Kravu pārvadāšanas pakalpojumi',
  },
  {
    id: 'parvaksanas',
    title: 'Pārvākšanās Serviss',
    icon: 'Box',
    description: 'Pārvākšanās pakalpojumi',
  },
  {
    id: 'kurjeri',
    title: 'Kurjeru Pakalpojumi',
    icon: 'Package',
    description: 'Sūtījumu piegāde',
  },
  {
    id: 'noma',
    title: 'Auto Noma',
    icon: 'Car',
    description: 'Transportlīdzekļu noma',
  },

  // Security & Safety
  {
    id: 'apsardze',
    title: 'Apsardze',
    icon: 'Shield',
    description: 'Apsardzes pakalpojumi',
  },
  {
    id: 'ugunsdzesiba',
    title: 'Ugunsdrošība',
    icon: 'Flame',
    description: 'Ugunsdrošības sistēmas',
  },
  {
    id: 'atsledznieki',
    title: 'Atslēdznieki',
    icon: 'Key',
    description: 'Atslēgu izgatavošana un durvju atvēršana',
  },
  {
    id: 'signalizacija',
    title: 'Signalizācijas Sistēmas',
    icon: 'Bell',
    description: 'Drošības sistēmu uzstādīšana',
  },

  // IT & Electronics
  {
    id: 'datori',
    title: 'Datoru Serviss',
    icon: 'Laptop',
    description: 'Datoru remonts un apkope',
  },
  {
    id: 'mobilie',
    title: 'Mobilo Telefonu Remonts',
    icon: 'Smartphone',
    description: 'Telefonu remonts',
  },
  {
    id: 'internets',
    title: 'Interneta Pakalpojumi',
    icon: 'Wifi',
    description: 'Interneta pieslēgums un apkope',
  },
  {
    id: 'majaslapa',
    title: 'Mājaslapu Izstrāde',
    icon: 'Code',
    description: 'Mājaslapu izstrāde un uzturēšana',
  },

  // Household Services
  {
    id: 'mebelu-remonts',
    title: 'Mēbeļu Remonts',
    icon: 'Tool',
    description: 'Mēbeļu labošana un restaurācija',
  },
  {
    id: 'sadzives-tehnika',
    title: 'Sadzīves Tehnika',
    icon: 'Tv',
    description: 'Sadzīves tehnikas remonts',
  },
  {
    id: 'dezinfekcija',
    title: 'Dezinfekcija',
    icon: 'Bug',
    description: 'Kaitēkļu iznīcināšana',
  },
  {
    id: 'chimistirija',
    title: 'Ķīmiskā Tīrītava',
    icon: 'Shirt',
    description: 'Apģērbu ķīmiskā tīrīšana',
  },

  // Outdoor Services
  {
    id: 'darznieki',
    title: 'Dārznieki',
    icon: 'Leaf',
    description: 'Dārza kopšana un labiekārtošana',
  },
  {
    id: 'teritorijas-uzkopsana',
    title: 'Teritorijas Uzkopšana',
    icon: 'Trash2',
    description: 'Teritorijas tīrīšana un atkritumu izvešana',
  },
  {
    id: 'zemes-darbi',
    title: 'Zemes Darbi',
    icon: 'Construction',
    description: 'Rakšanas un planēšanas darbi',
  },
  {
    id: 'labiekartosana',
    title: 'Teritorijas Labiekārtošana',
    icon: 'TreePine',
    description: 'Teritorijas labiekārtošana un apzaļumošana',
  },

  // Education & Training
  {
    id: 'valodu-kursi',
    title: 'Valodu Kursi',
    icon: 'GraduationCap',
    description: 'Valodu apmācība',
  },
  {
    id: 'autoskola',
    title: 'Autoskola',
    icon: 'Car',
    description: 'Autovadītāju apmācība',
  },
  {
    id: 'sports',
    title: 'Sporta Nodarbības',
    icon: 'Dumbbell',
    description: 'Sporta un fitnesa nodarbības',
  },

  // Entertainment & Events
  {
    id: 'fotografs',
    title: 'Fotogrāfi',
    icon: 'Camera',
    description: 'Fotografēšanas pakalpojumi',
  },
  {
    id: 'svetku-agenturas',
    title: 'Svētku Aģentūras',
    icon: 'PartyPopper',
    description: 'Pasākumu organizēšana',
  },
  {
    id: 'muzika',
    title: 'Muzikanti',
    icon: 'Music',
    description: 'Muzikālie pakalpojumi',
  },
];